/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3e491ff2-208d-4e20-9509-120e389df7e6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QHil0CDCG",
    "aws_user_pools_web_client_id": "6s7ae6l4kvdb9lq7ust6smog9f",
    "oauth": {},
    "aws_user_files_s3_bucket": "paigelocklin6e775f92800b4ecdb9b2dca0d00fd031113237-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "dd5ff70d224445449f3df9bc422da28e",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_content_delivery_bucket": "paigelocklin-20210420064259-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dghe7vl1s401v.cloudfront.net"
};


export default awsmobile;
