import './App.css'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from "styled-components"
import ls from 'local-storage'

//components
import Modal from 'react-overlays/Modal'
import Terms from './components/Terms'

// logos/icons
import fansly_logo from './fansly_logo.jpg'
import twitch_logo from './Twitch_logo.svg'
import fansly_icon from './fansly_small.png'
import twitter_icon from './twitter_icon.svg'
import twitch_icon from './twitch_icon.svg'
import insta_icon from './insta-icon.svg'
import tiktok_icon from './tiktok.svg'
import hub_icon from './pornhub.png'
import xbiz_logo from './xbiz.svg'

//aws
import Amplify, { Analytics } from 'aws-amplify';
import awsconfig from './aws-exports';

// Constants
const FANSLY_URL = "https://fansly.com/PaigeLocklin"
const TWITTER_URL = "https://twitter.com/PaigeLocklin"
// const FET_URL = "https://fetlife.com/users/12679354"
const TWITCH_URL = "https://www.twitch.tv/paigemaster_gaming"
// const TIKTOK_URL = "https://www.tiktok.com/@paigelocklin?"
const INSTA_URL = "https://www.instagram.com/PaigeLocklin/"
const HUB_URL = "https://www.pornhub.com/model/paigelocklin"
const OF_REVIEWS_URL = "https://imgur.com/a/SLsTUGN"
const XBIZ_ARTICLE = "https://www.xbiz.com/news/258910/paige-locklin-touts-choose-your-own-adventure-onlyfans-content"

function App() {
    Amplify.configure(awsconfig);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1480px)' })

    const [termsShow, termsSetShow] = useState(false);
    const ageVerified = () => { return ls.get('paigeageverified') }

    function clickTwitterHub(soc) {
        let lstag = "initialclick" + soc
        if (!ls.get(lstag)) {
            ls.set(lstag, Date.now())
        }
    }

    function secdif(start, end) {
        return Math.floor((end - start) / 1000)
    }

    function clickOnlyFans(area) {
        let twittertime = ls.get("initialclicktwitter")
        let hubtime = ls.get("initialclickhub")
        return {
            'name': "clickOnlyFans",
            'attributes': { "area": area },
            'metrics': {
                'timeSinceTwitter': twittertime ? secdif(twittertime, Date.now()) : -1,
                'timeSinceHub': hubtime ? secdif(hubtime, Date.now()) : -1,
            }
        }
    }

    function verifyAge() {
        ls.set('paigeageverified', true);
        window.location.reload(true);
    }

    const Backdrop = styled("div")`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        opacity: 0.93;
        z-index:9997;
    `;

    const DisclaimerModal = styled(Modal)`
        position: fixed;
        width: 400px;
        height: 150px;
        z-index: 9998;
        top: 50%;
        left: 50%;
        margin-top: -75px;
        margin-left: -200px;
        border: 1px solid #e5e5e5;
        background-color: gray;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    `;

    const TermsModal = styled(Modal)`
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 9999;
        border: 1px solid #e5e5e5;
        background-color: gray;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        padding: 20px;
        display: block;
        align-items: center;
        justify-content: center;
    `;

    const renderBackdrop = (props) => <Backdrop {...props} />;

    return (
        <div className={isTabletOrMobile ? "App App-mobile" : "App"}>
        <DisclaimerModal
            show={!ageVerified()}
            renderBackdrop={renderBackdrop}
            aria-labelledby="modal-label"
            id="modal"
        >
        <div>
        <h4 id="modal-label">Welcome to the Official Website of Paige Locklin!</h4>
        <div id="modal-text">This website contains adult-oriented material and is only available to adults. If you are under the age of 18 (or 21 in some countries), if it is illegal to view such material in your jurisdiction or it offends you, please do not continue. By entering you agree to the Terms of Use to use the website.</div>
        <button
        type="button"
        className="btn btn-primary mb-4"
        onClick={verifyAge}
        >Agree & Enter</button>
        <button
            type="button"
            className="btn btn-primary mb-4"
            onClick={() => {
                window.location.href="https://www.google.com"
                Analytics.record({ "name": 'DisclaimerDecline' });
            }}
        >Leave</button>
        </div>
        </DisclaimerModal>
        <TermsModal
            show={termsShow}
            id="termsmodal"
            aria-labelledby="modal-label"
            renderBackdrop={renderBackdrop}
        >
        <div>
        <div className="termstext"><Terms /></div>
        <div className="termsfooter">
        <button
            type="button"
            onClick={() => termsSetShow(false) }
        >Agree and Close</button>
        </div>
        </div>
        </TermsModal>
        <header className={isTabletOrMobile ? "App-header mobile-header" : "App-header"}>
            <div className={isTabletOrMobile ? "headerlinks headerlinks-mobile" : "headerlinks"}>
            <a className="headerlink" href="#fansly">Fansly</a>
            <a className="headerlink" href="#twitch">Twitch</a>
            <a className="headerlink" href="#aboutme">About</a>
            <a className="headerlink" href="#media">Media</a>
            </div>
            <img className={isTabletOrMobile ? "headerbanner mobile-headerbanner" : "headerbanner"} src="https://i.imgur.com/bG5kTXH.jpg" alt="Paige Locklin" />
            <div className={isTabletOrMobile ? "headersocials headersocials-mobile" : "headersocials"}>
            <a className="Social-icon" href={INSTA_URL} target="_blank" rel="noreferrer" onClick={() => {Analytics.record({ "name": 'headerClickInsta' });}}>
                <img className="Social-icon" src={insta_icon} alt="My Instagram" />
            </a>
            <a className="Social-icon" href={TWITTER_URL} target="_blank" rel="noreferrer" onClick={() => {Analytics.record({ "name": 'headerClickTwitter' }); clickTwitterHub("twitter")}}>
                <img className="Social-icon" src={twitter_icon} alt="My Twitter" />
            </a>
            <a className="Social-icon" href={TWITCH_URL} target="_blank" rel="noreferrer" onClick={() => {Analytics.record({ "name": 'headerClickTwitch' });}}>
            <img className="Social-icon" src={twitch_icon} alt="My Twitch" />
            </a>
            <a className="Social-icon" href={FANSLY_URL} target="_blank" rel="noreferrer" onClick={() => {Analytics.record(clickOnlyFans("header"));}}>
            <img className="Social-icon" src={fansly_icon} alt="My Fansly" />
            </a>
            </div>
      </header>
      <div className={isTabletOrMobile ? "body-mobile app-body" : "app-body"}>
      <div className="fansly contentsection" id="fansly">
                <img className={isTabletOrMobile ? "banner banner-mobile" : "banner"} src="https://i.imgur.com/ClycP1r.jpeg" alt="Fansly"/>
            <div className={isTabletOrMobile ? "social-content mobile-content" : "social-content"}>
            <div className={isTabletOrMobile ? "oftext mobilecol" : "oftext leftcol"}>
            <br /><h3>Welcome Traveler! If you're here there is a really good chance you are trying to see where you can find me naked. Your exceptional passive perception revealed this link! </h3>             <div className="of-link">
                <a href={FANSLY_URL} target="_blank" rel="noreferrer" onClick={() => {Analytics.record(clickOnlyFans("content"));}}>
                <img src={fansly_logo} alt="Fansly" />
                </a>
            </div>
            <br />
            Featured in an article on <a className="textLink" href={XBIZ_ARTICLE} target="_blank" rel="noreferrer" onClick={() => {Analytics.record({ "name": 'contentClickXbiz' });}}>XBIZ</a>!
            <br />
            <br />
            I started my Fansly because I am an exhibitionist at heart and doing this work has brought me a lot of happiness. What will you find on my Fansly wall, you ask? Well, I post at least once a day, including videos at least once a week, and always respond to DM's. You will also find:<br />
            <h3>Creative Original Content</h3>
            Home of the universe's greatest Choose Your Own Adventure Porn and naughty video game livestreams!
            <h3>Humor</h3>
             Be ready to laugh while you fap! Whether you're having your sides split while rubbing your clit or busting a gut while you're busting a nut, I promise you will have a good time!

            <h3>Nerdiness</h3>
            I put my whole personality into my porn, which includes a lot of my nerdiness and quirks. Be ready for porn featuring video games, D&D, outer space, tentacles and much, much more!

            <h3>Inclusivity</h3>
            My wall is an LGBTQIA+ space and I welcome and include content for all genders and orientations.🌈

            <h3>Kink</h3>
            I've been a very active member of the BDSM community for well over a decade now, and bring the experience from that to my porn. You can expect to see content regularly in all manners of BDSM including:
            <div className="contentlist"><ul className="contentlist">
            <li>Femdom</li>
            <li>Chastity</li>
            <li>Foot/Boot fetishes</li>
            <li>Bondage</li>
            <li>Forced Orgasms</li>
            <li>Impact</li>
            <li>Fire play</li>
            <li>Predicaments</li>
            <li>Fucking machines/sybians</li>
            <li>Erotic roleplay</li>
            </ul></div>

            <h3>Available extras</h3>
            <ul className="contentlist">
            <li>Occasional PPVs in addition to my regular videos</li>
            <li>Custom videos/pictures</li>
            <li>1:1 cam or gaming sessions - with optional remote control toys</li>
            <li>Dick/Pussy/Body part ratings</li>
            <li>Joyfriend Experience</li>
            </ul>
            <br/><br /><em>More info about all of this on my Fansly wall, or just ask... come say hi!</em><br/><br/>
            <a href={OF_REVIEWS_URL} target="_blank" rel="noreferrer "onClick={() => {Analytics.record({ "name": 'contentClickOnlyFansReviews' });}}>
            <img className="testimonials" src="https://i.imgur.com/lmKruo0.png" alt="Testimonials" />
            </a>
            </div>
            <div className={isTabletOrMobile ? "mobilecol" : "rightcol"}>
                <img className="social-image" src="https://i.imgur.com/p5hPGAe.jpg" alt="" />
            </div>
            </div>
      </div>
      <div className="twitch contentsection" id="twitch">
            <img className={isTabletOrMobile ? "banner banner-mobile" : "banner"} src="https://i.imgur.com/Kioh69S.jpg" alt="Twitch"/>
            <div className={isTabletOrMobile ? "social-content mobile-content" : "social-content"}>
                <div className={isTabletOrMobile ? "twitchtext mobilecol" : "twitchtext leftcol"}>
                <br />I started a twitch channel because of my ultimate love of video games and have always enjoyed sharing my gaming with friends. I'm an outgoing and boisterous person and if you join one of my streams you'll typically find me waving my arms around with lots of energy while I talk. I love being able to engage with people while playing and it has been an incredibly fun experience for me.
                <br /><br />
                I am a Twitch affiliate, which means that you can subscribe to my channel if you want to! It is free to do with Amazon Prime, and I have been using the money I've made towards better streaming gear (lights, stream deck, etc). People who are subscribed to both my Twitch and Fansly get bonus sexy content regularly!
                <br /><br />
                Every day I feel so inspired and thankful for all of you who have followed and are supporting what I do. Thank you!!! 💖
                <h3>Streaming every Tuesday and Thursday from 7-9pm EST</h3>
                <h4>Click below to come see my channel!</h4>
                <div className="twitch-link">
                    <a href={TWITCH_URL} target="_blank" rel="noreferrer" onClick={() => {Analytics.record({ "name": 'contentClickTwitch' });}}>
                        <img className="twitch-logo" src={twitch_logo} alt="My Twitch" />
                    </a>
                </div>
                </div>
                <div className={isTabletOrMobile ? "mobilecol" : "rightcol"}>
                    <img className="social-image" src="https://i.imgur.com/kfCeDrE.jpg" alt="Kigurumi Boobs" />
                </div>
            </div>
      </div>
      <div className="aboutme contentsection" id="aboutme">
      <img className={isTabletOrMobile ? "banner banner-mobile" : "banner"} src="https://i.imgur.com/vLh0Sql.jpg" alt="About Me"/>
      <div className={isTabletOrMobile ? "social-content mobile-content" : "social-content"}>
      <div className={isTabletOrMobile ? "abouttext mobilecol" : "abouttext leftcol"}>
      <br />Hi! I'm Paige, I'm queer and go by any pronouns. 🌈<br />
      <br />
      I am a self proclaimed nerd and video games has always been a big part of my life. I grew up on an N64 and Zelda OOT will always be one of my favorites. I also have a super amazing husband, 2 cats, and a doggo who is trained by my senior kitty.<br />
      <br />
      Lets see...I also play a lot of dungeons and dragons, paint miniatures, enjoy board game nights with my friends, and I love hiking. I went to Yellowstone for the first time a few years ago and hiked 50 miles and it was an amazing achievement for me, I just love the outdoors!<br />
      <br />
      Speaking of outdoors and being a nerd, I LARP a lot! A few times a year you'll find me running around in the woods with giant foam weapons bopping my friends and I simply love it!<br />
      <br/>
      I am a passionate, motivated person with a big heart and a bigger butt. I put a lot of effort into my work because I enjoy making other people happy and I'm glad you are here.
      </div>
      <div className={isTabletOrMobile ? "mobilecol" : "rightcol"}>
      <img className="social-image" src="https://i.imgur.com/cTcca8Z.jpg" alt="Paige" />
      </div>
      </div>
      </div>
      <div className="portfolio" id="media">
            <img className="portfolio-item leftcol" src="https://i.imgur.com/vlxJcWY.jpg" alt="Ball gag drool" />
            <img className="portfolio-item rightcol" src="https://i.imgur.com/pphUdyX.jpg" alt="Nerf This" />
            <img className="portfolio-item" src="https://i.imgur.com/1QlfSfm.jpg" alt="D.Va bunny" />
            <img className="portfolio-item" src="https://i.imgur.com/fqofQIS.jpg" alt="Kigurumi lick"/>
            <img className="portfolio-item" src="https://i.imgur.com/HPr4iEi.jpg" alt="Kigurumi sexy"/>
            <img className="portfolio-item" src="https://i.imgur.com/syiek2P.jpg" alt="Kneel before me" />
            <img className="portfolio-item" src="https://i.imgur.com/A1Con3n.jpg" alt="Readying my whip" />
            <img className="portfolio-item" src="https://i.imgur.com/coyo84i.jpg" alt="Butt in fishnets" />
            <img className="portfolio-item" src="https://i.imgur.com/zNKGUc4.jpg" alt="Dramatic shadow from above" />
            <img className="portfolio-item" src="https://i.imgur.com/w1AinsK.jpg" alt="Get down" />
      </div>
      <div className="App-footer">
        <div className="footertext"> Still scrolling and looking for a lot more content with a lot less clothing? </div>
        <div className="social-link">
            <a href={FANSLY_URL} target="_blank" rel="noreferrer" onClick={() => {Analytics.record(clickOnlyFans("footer"));}}>
            <img src={fansly_logo} alt="My Fansly" />
            </a>
        </div>
        <div className="copyright">
            Copyright © 2021 PaigeLocklin. All Rights Reserved.
            <a href="javascript:void(0)" onClick={ () => {
                termsSetShow(true)
                Analytics.record({ "name": 'footerClickTerms'})
            }}> Terms of Use</a>
                    </div>
      </div>
      </div>
    </div>
  )
}

export default App
